import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text } from "../bruin"
import styled from "styled-components"
import { motion } from "framer-motion"

const Hero = styled.div`
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #b8cce3;
	background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23081556' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
	background-size: cover;

	h1 {
		font-size: 12rem;
		letter-spacing: 0.5rem;
		color: #ffb74d;
	}

	@media screen and (max-width: 600px) {
		h1 {
			font-size: 8rem;
		}
	}
`
const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<Hero>
			<Heading as="h1" style={{ textShadow: "4px 2px 1px hsl(218, 100%, 17%)" }} align="center">
				Grayhaber
			</Heading>
			<Text fontSize="hu" align="center">
				Coming Soon.
			</Text>
			<motion.div
				animate={{
					scale: 2,
				}}
				drag="x"
				dragConstraints={{ left: -100, right: 100 }}
				style={{
					height: "100px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					width: "100px",
					background: "orange",
					margin: "0 auto",
					cursor: "pointer",
				}}
			>
				<Text align="center">Drag me!</Text>
			</motion.div>
		</Hero>
	</Layout>
)

export default IndexPage
